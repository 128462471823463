import { message as antdMessage } from 'antd';
import round from 'lodash/round';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import {
  DEFAULT_PRECISION_IMPERIAL,
  WATER_TANK_LEVEL_PRECISION,
  WATER_TANK_VOLUME_PRECISION
} from 'constants/samplePoint';
import useAssetType from 'store/modules/assetTypes/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import convertMetricToImperial, {
  convertMetricToImperialAlwaysRounding
} from 'utils/convert-metric-to-imperial';
import downloadFile from 'utils/download-file';
import { UnitType, getUnitByCountry } from 'utils/get-unit-by-country';

import { constructSampleUrlForCSVExport } from './utils/construct-sample-url-for-csv-export';

import { CSV_DATE_FORMAT, CSV_FILE_NAME_DATE_FORMAT } from '.';

// Check more shared comments in useExportRainGaugeCsv
export default function useExportWaterTankCsv(
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const dispatch = useDispatch();
  const country = useEnterpriseCountry();
  const {
    id,
    name,
    siteTimezoneCode,
    assetTypeId,
    config: { totalCapacity, maxValue: height }
  } = samplePoint;
  const assetType = useAssetType(assetTypeId);

  if (!siteTimezoneCode) {
    antdMessage.error('Site time zone is not set');
    return;
  }

  return async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      const samples = await new Promise<Sample[]>((resolve, reject) => {
        setLoading(true);
        const url = constructSampleUrlForCSVExport(id, siteTimezoneCode, dateRange);
        dispatch(
          ephemeralGetRequest<Sample[]>(url, resolve, reject)
        );
      });

      const csvArray = samples
        .sort((a, b) => b.date - a.date)
        .map((s) => {
          const levelLocale = convertMetricToImperialAlwaysRounding(
            UnitType.LEVEL_SM,
            country,
            s.rwValue,
            WATER_TANK_LEVEL_PRECISION
          );
          const date = moment(s.date)
            .tz(siteTimezoneCode)
            .format(CSV_DATE_FORMAT);
          let row = `${date},${levelLocale}`;
          // to calculate volume accurately
          const levelLocaleHighPrecision = convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            s.rwValue,
            DEFAULT_PRECISION_IMPERIAL
          );
          if (totalCapacity && height && levelLocaleHighPrecision) {
            const volume = round(
              (levelLocaleHighPrecision / height) * totalCapacity,
              WATER_TANK_VOLUME_PRECISION
            );
            row += `,${volume}`;
          }
          return row;
        });
      const levelSuffix = getUnitByCountry({
        unitType: UnitType.LEVEL_SM,
        country
      });
      const volumeSuffix = getUnitByCountry({
        unitType: UnitType.VOLUME_SM,
        country
      });
      let headers = `Date and Time,Water Level (${levelSuffix})`;
      if (totalCapacity) {
        headers += `,Volume (${volumeSuffix})`;
      }
      csvArray.unshift(headers);

      downloadFile(
        `${name} ${assetType.name} ${dateRange[0].format(
          CSV_FILE_NAME_DATE_FORMAT
        )}-${dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT)}.csv`,
        csvArray.join('\n'),
        'text/csv'
      );

      onFinish?.(e);
      antdMessage.success('CSV exported successfully');
    } catch (error) {
      antdMessage.error(
        `Error exporting CSV: ${error instanceof Error ? error.message : error
        }`
      );
    } finally {
      setLoading(false);
    }
  };
}
