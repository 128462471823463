import keyBy from 'lodash/keyBy';

import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';
import { mergeGroupedCollectionsByKey } from 'utils/Array/merge-grouped-collections-by-key';
import { convertDictionaryToRecord } from 'utils/Dictionary/convert-dictionary-to-record';

import { UserPermissionActionTypes } from './constants';
import { UserPermissionsState } from './types';

const initialState: UserPermissionsState = {};

function userPermissionsReducer(
  state: UserPermissionsState = initialState,
  action: ApplicationActions
): UserPermissionsState {
  switch (action.type) {
    case UserPermissionActionTypes.SET_USER_PERMISSIONS: {
      const newPermissions: Record<number, any[]> = convertDictionaryToRecord(keyBy(action.payload, 'userId'));
      return mergeGroupedCollectionsByKey(state, newPermissions, 'id');
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default userPermissionsReducer;