enum ActionTypes {
  FETCH_MY_PREFERENCES_REQUEST = 'FETCH_MY_PREFERENCES_REQUEST',
  FETCH_MY_PREFERENCES_SUCCESS = 'FETCH_MY_PREFERENCES_SUCCESS',
  FETCH_MY_PREFERENCES_FAILURE = 'FETCH_MY_PREFERENCES_FAILURE',
  SET_MY_PREFERENCES = 'SET_MY_PREFERENCES',

  EDIT_MY_PREFERENCE_REQUEST = 'EDIT_MY_PREFERENCE_REQUEST',
  EDIT_MY_PREFERENCE_SUCCESS = 'EDIT_MY_PREFERENCE_SUCCESS',
  EDIT_MY_PREFERENCE_FAILURE = 'EDIT_MY_PREFERENCE_FAILURE',

  UNSET_MY_PREFERENCE = 'UNSET_MY_PREFERENCE'
}

export default ActionTypes;