import axios from 'axios';

import { UserPermission } from 'types/models/user-permission';
import { EntityId, EntityKind } from 'types/models/user-preference';
import { APIResponseParsed } from 'types/response';
import { getRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

// ================================================
// TYPES
// ================================================
type FetchUserPermissionsParams = {
  entityKind?: EntityKind;
  /** If specified, must specify an entityKind too */
  entityId?: EntityId;
};

// ================================================
// REQUESTS
// ================================================
async function fetchUserPermissions(
  params: FetchUserPermissionsParams
): Promise<APIResponseParsed<UserPermission[]>> {
  try {
    const response = await getRequest<UserPermission[]>('user-permissions', { params });
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

export default {
  fetchUserPermissions
};