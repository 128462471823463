export function convertDictionaryToRecord(dict: { [key: string]: any }): Record<number, any[]> {
  const record: Record<number, any[]> = {};

  Object.entries(dict).forEach(([key, value]) => {
    const numericKey = Number(key);
    if (!isNaN(numericKey)) {
      record[numericKey] = Array.isArray(value) ? value : [value];
    }
  });

  return record;
}